import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import Layout from '@components/layout'
import SubscribeForm from '@components/forms/subscribe-form'
import WatewaterAudit from '@components/wastewater-audit'
import MustRead from '@components/must-read'

const SubscribePage = () => {
  return (
    <Layout pageTitle="Subscribe">
      <Container fluid="xxl">
        <Row>
          <Col className="no-gutters">
            <div className="section hero-banner text-center">
              <StaticImage
                className="hero-image"
                src="../media/wessuc_default_hero.png"
                alt=""
                aspectRatio={3.5 / 1}
                objectPosition="center top"
                layout="fullWidth" />
              <div className='hero-text'>
                <h3>THE WESSUC E-NEWS</h3>
                <h5>Wake Up With Wessuc</h5>
                <p>
                  Subscribe here for our monthly e-newsletter
                </p>
                <SubscribeForm />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <WatewaterAudit />
      <MustRead />
    </Layout>
  )
}

export default SubscribePage