import * as React from 'react'

const SubscribeForm = () => {
  return (
    <div>
      <script type="text/javascript" src="https://campaigns.zoho.com/js/zc.iframe.js"></script>
      <iframe
        id="e-news-iframe"
        frameborder="0"
        title="Wessuc E-News Sign Up"
        src="https://wesu.maillist-manage.com/ua/Optin?od=11287ecaf32434&zx=12cd11cd2&lD=1780bd372f86a666&n=11699f7500138e9&sD=1780bd372f92b08f">
      </iframe>
    </div>
  )
}

export default SubscribeForm